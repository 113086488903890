import useTableData from '@/hooks/useTableData'
import { IConfigSeller } from '@/models/configSeller'
import { webApiService } from '@/services'
import { currencyFormat } from '@/utils/currencyFormat'
import { StatusColor } from '@/utils/StatusColor.utility'
import { Button, Chip, Input, Spinner, Tab, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tabs, Selection } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { MdCloudDownload, MdOutlineSearch } from 'react-icons/md'
import { useSelector } from 'react-redux'
import ModalCustodia from './components/ModalCustodia'
import { UTCDateToCountryDate } from '@/utils/countryDate.util'
import ModalFolio from './components/ModalFolio'
import { useParams } from 'react-router-dom'

const columns = [
  { name: '#', uid: 'id' },
  { name: 'Nombre', uid: 'name' },
  { name: 'Email', uid: 'email' },
  { name: 'Dispositivo', uid: 'sbu' },
  { name: 'Marca', uid: 'brand' },
  { name: 'Modelo', uid: 'model' },
  { name: 'Monto Descuento', uid: 'discount_amount' },
  { name: 'Código', uid: 'login_code' },
  { name: 'Estado', uid: 'status' },
  { name: 'Fecha de Entrega', uid: 'fecha_entregado' },
  { name: 'Folio', uid: 'folio' },
  { name: '', uid: 'actions' }
]

const excelName = 'Listado_TradeIn_Recibidos'

const TableRecibidos = () => {
  const [data, setData] = useState<{ loading: boolean, list: any[], tab: string, edit: { value: string, id: number, active: boolean } }>({ loading: false, list: [], tab: 'recibidos', edit: { value: '', id: 0, active: false } })
  const {
    selectedKeys,
    sortDescriptor,
    filterValue,
    sortedItems,
    bottomContent,
    onSearchChange,
    setSortDescriptor,
    setPage,
    onClear,
    exportToCSV,
    setSelectedKeys
  } = useTableData(columns, excelName, data.list)
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const isSamsungPeru = configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'
  const userState = useSelector((store: any) => store.user)
  const { slug } = useParams()

  const loadData = async () => {
    setData({ ...data, loading: true })
    await webApiService.getListTradeIn({
      email: userState.email,
      poolId: String(import.meta.env.VITE_COGNITO_POOL_ID),
      listStatus: data.tab === 'recibidos' ? [5] : data.tab === 'retirado' ? [7] : [9],
      id_role: userState.id_role,
      seller: slug
    }).then((response) => {
      console.log(data.tab)
      setData({ ...data, loading: false, list: data.tab === 'custodia' ? response.data.filter((e:any) => e.seal === '') : data.tab === 'sello' ? response.data.filter((e:any) => e.seal !== '') : response.data })
      setSelectedKeys(new Set([]))
      setPage(1)
    })
  }

  const renderCell: any = (item: any, columnKey: any) => {
    const cellValue = item[columnKey]

    /* eslint default-case: "error" */
    switch (columnKey) {
      case 'discount_amount':
        return (<span className='font-bold text-xs'>{currencyFormat(Number(cellValue), import.meta.env.VITE_COUNTRY)}</span>)
      case 'name':
        return (<span className='font-bold text-xs'>{item[columnKey] + ' ' + item.lastname}</span>)
      case 'status':
        return (<Chip color='primary' style={{ '--status-color': `${StatusColor(cellValue)}`, backgroundColor: 'var(--status-color)' } as CSSProperties} size='sm' variant='solid' className=''>{cellValue}</Chip>)
      case 'folio':
        if ([Number(import.meta.env.VITE_PERFIL_EJECUTIVO)].includes(userState.id_role) && configSeller.config.permisos.EJECUTIVO_ENABLE_FOLIO === 0) {
          return cellValue
        }
        if ([Number(import.meta.env.VITE_PERFIL_CUSTODIA)].includes(userState.id_role)) {
          return cellValue
        }
        if (data.tab !== 'recibidos') {
          return cellValue
        }
        return <div className='flex items-center'><span>{cellValue}</span> <Button color='primary' size='sm' className='mx-1' onClick={() => { setData({ ...data, edit: { value: item.folio, id: item.id, active: true } }) }}>editar</Button></div>
      case 'fecha_entregado':
        return UTCDateToCountryDate(cellValue)
      default:
        return (<span className='text-xs'>{cellValue}</span>)
    }
  }

  const topContent = useMemo(() => {
    return Array.from(selectedKeys).length > 0
      ? (
        <div className='flex flex-col gap-4'>
          <div className='flex justify-end gap-3 items-end'>
            <ModalCustodia tab={data.tab} list={data.list} seleccionados={selectedKeys === 'all' ? data.list.map(item => item.id) : Array.from(selectedKeys)} reloadAction={loadData} visible={sortedItems.filter((el) => Array.from(selectedKeys).map(e => Number(e)).includes(el.id)).every(ele => ele.folio !== null && ele.folio !== '')} />
          </div>
        </div>
        )
      : null
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys])

  const handleSelectionChange = (keys: Selection | 'all') => {
    console.log('keys => ', keys)
    // Check if the selection includes all items
    if (keys === 'all') {
      // Deselect all items if all are selected
      setSelectedKeys(new Set())
    } else {
      setSelectedKeys(keys)
    }
  }

  useEffect(() => {
    console.log('useeffect')
    loadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.tab])

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }} className='space-y-4'
    >
      {!isSamsungPeru &&

        [Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_CUSTODIA), Number(import.meta.env.VITE_PERFIL_JEFETIENDA)].includes(userState.id_role)
        ? (
          <Tabs selectedKey={data.tab} isDisabled={data.loading} variant='solid' aria-label='Tabs variants' className={[Number(import.meta.env.VITE_PERFIL_ADMIN)].includes(userState.id_role) ? 'mt-4' : ''} onSelectionChange={(value) => { console.log(value); setData({ ...data, tab: String(value) }) }}>
            <Tab key='recibidos' title='Recibir' />
            <Tab key='custodia' title='En Custodia' />
            <Tab key='sello' title='Con sello' />
            <Tab key='retirado' title='Retirado' />
          </Tabs>)
        : null}
      <div className='grid grid-cols-2'>
        <div className='my-4'>
          <Input
            label='Search'
            isClearable
            radius='lg'
            value={filterValue}
            onValueChange={onSearchChange}
            onClear={() => onClear()}
            classNames={{
              label: 'text-black/50 dark:text-white/90',
              input: [
                'bg-transparent',
                'text-black/90 dark:text-white/90',
                'placeholder:text-default-700/50 dark:placeholder:text-white/60'
              ],
              innerWrapper: 'bg-transparent',
              inputWrapper: [
                'bg-default-200/50',
                'dark:bg-default/60',
                'backdrop-blur-xl',
                'backdrop-saturate-200',
                'hover:bg-default-200/70',
                'dark:hover:bg-default/70',
                'group-data-[focus=true]:bg-default-200/50',
                'dark:group-data-[focus=true]:bg-default/60',
                '!cursor-text'
              ]
            }}
            placeholder='Buscar...'
            startContent={
              <MdOutlineSearch className='text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0' />
        }
          />
        </div>
        <div className='flex items-center justify-end'>
          <Button onClick={exportToCSV} color='success' className='text-white' variant='shadow' endContent={<MdCloudDownload />}>Descargar</Button>
        </div>
      </div>
      <Table
        color='secondary'
        selectionMode={[Number(import.meta.env.VITE_PERFIL_ADMIN), Number(import.meta.env.VITE_PERFIL_CUSTODIA), Number(import.meta.env.VITE_PERFIL_JEFETIENDA)].includes(userState.id_role) && data.tab !== 'sello' ? 'multiple' : 'none'}
        selectedKeys={selectedKeys}
        onSelectionChange={handleSelectionChange}
        aria-label='Example table with client side sorting 22'
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        bottomContent={bottomContent}
        bottomContentPlacement='outside'
        topContent={topContent}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.uid} align={column.uid === 'actions' ? 'center' : 'start'} allowsSorting={column.uid !== 'actions'} className={column.uid === 'nombre' || column.uid === 'actions' || column.uid === 'ordenCompraCodigo' ? '' : 'md:table-cell'}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={data.loading}
          loadingContent={<Spinner label='Loading...' />}
        >
          {
            sortedItems.map((item: any) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <ModalFolio data={data} setData={setData} folio={data.edit.value} id={data.edit.id} isOpen={data.edit.active} onClose={() => { setData({ ...data, edit: { ...data.edit, active: !data.edit.active } }) }} />
    </motion.div>
  )
}

export default TableRecibidos
