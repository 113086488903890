import { FC } from 'react'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { PrivateRoutes } from '../constants/routes'

interface IOwnProps{
  sellerSlug: string
}

const SlugRouterGuard: FC<IOwnProps> = ({ sellerSlug }) => {
  const { slug } = useParams<{ slug: string }>()
  const PrivateValidationFragment = <Outlet />

  return slug?.toUpperCase() === sellerSlug.toUpperCase() ? PrivateValidationFragment : <Navigate replace to={PrivateRoutes.HOME.replace(':slug', String(slug))} />
}

export default SlugRouterGuard
