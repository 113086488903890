import axios from 'axios'

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8', Accept: 'text/json' }
})

export const sourceAxios = axios.CancelToken.source()

export const webApiService = {

  createLoginCode: async () => {
    const url = `${import.meta.env.VITE_CRUD_PHONECHECK}createLoginCode`
    return await axiosInstance.request({ method: 'POST', url }).then((res: any) => res.data)
  },
  save: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}save`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllSbu: async (slug: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllSbu/${slug}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllBrandBySbu: async (idSbu: string, slug: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllBrandBySbu/${idSbu}/${slug}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllModelByBrand: async (idSbu: string, idBrand: string, slug: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllModelByBrand/${idSbu}/${idBrand}/${slug}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllCapacityByModel: async (data: { idSbu: string, idBrand: string, idModel: string, seller: string }) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllCapacityByModel`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllCPU: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllCPU`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllGenerationByCPU: async (idCPU: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllGenerationByCPU/${idCPU}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllScreenByCPU: async (idCPU: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllScreenByCPU/${idCPU}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllRamByCPU: async (idCPU: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllRamByCPU/${idCPU}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllCapacity: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllCapacity`
    return await axiosInstance.request({ method: 'POST', data, url }).then((res: any) => res.data)
  },
  getAllQuestions: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllQuestions`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getConfigBySlug: async (slug: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getConfigBySlug/${slug}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getUserProfile: async (_email: string, _poolId: string) => {
    const url = `${import.meta.env.VITE_SERVICE_USER_PROFILE}getByEmailAndPoolId/${_email}/${_poolId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getCountStatus: async (email: string, seller: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getCountStatus/${email}/${seller}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getListTradeIn: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getList`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  updateOnlyFolio: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}updateOnlyFolio`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  updatePCheck: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}updatePCheck`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  checkSeal: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}checkSeal`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  rechazarTradein: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}rechazarTradein`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllCR: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllCR`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  update: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}update`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  resendCode: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}resendCode`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  recalculate: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}recalculate`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  saveFiles: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}saveFiles`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getFiles: async (_tradeinId: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getFiles/${_tradeinId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getHistoryById: async (data: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getHistoryById`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getById: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getById/${data.id_tradein}/${data.email}/${data.id_role}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getChecklistQuestions: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getChecklistQuestions`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getEntrysById: async (_tradeinId: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAnswersById/${_tradeinId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  sendToCustody: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}sendToCustody`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllBySeal: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllBySeal`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  validitySeal: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}validitySeal`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getDataByModel: async (data: { idSbu: string, idBrand: string, idModel: string, seller: string }) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getDataByModel`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllGPU: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllGPU`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllAnswers: async (idTradein: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllAnswers/${idTradein}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getTradeinConfig: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getTradeinConfig`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getByEnvelopeId: async (idContract: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getByEnvelopeId/${idContract}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  signContract: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}signContract`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  sendMails: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}sendMails`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  createDevice: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}createDevice`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => console.log('respuesta => ', res))
  },
  saveFromBulk: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}saveFromBulk`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getListSbu: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getListSbu/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  saveFormAnswers: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}saveFormAnswers/`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  saveDataAccount: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}saveDataAccount/`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllDataBank: async () => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllDataBank/`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  uploadProof: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}uploadProof/`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  inWarehouse: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}inWarehouse`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllStoreBySeller: async (seller: string) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getAllStoreBySeller/${seller}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getSkuByModel: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}getSkuByModel`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  sendGiftcard: async (data: any) => {
    const url = `${import.meta.env.VITE_CRUD_TRADEIN}sendGiftcard`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  }
}
