import { ITradeinContext } from '@/models/ITradein'
import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import React, { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

export type TradeinContextType = {
  ctx: ITradeinContext;
  saveCtx: (ctx: ITradeinContext) => void;
};

const EmptyObject: ITradeinContext = {
  tradein: {
    id: 0,
    sbu: '',
    brand: '',
    model: '',
    phone: '',
    cpu: '',
    cpu_generation: '',
    screen: '',
    ram: '',
    capacity: '',
    folio_coppel: '',
    name: '',
    lastname: '',
    email: '',
    contract_email: '',
    rut: '',
    discount_amount: 0,
    serie: '',
    login_code: '',
    certificate_theft: '',
    certificate_possession: '',
    entry_form_id: 0,
    entry_form_status: 0,
    docusign_id: '',
    id_status: 0,
    status: '',
    blacklistStatus: '',
    make: '',
    modelName: '',
    modelNo: '',
    memory: '',
    store: null,
    recalculate: '',
    wasRecalculated: 0,
    hasFiles: 0,
    new_discount_amount: 0,
    cpuGeneration: '',
    screenSize: '',
    storage: '',
    id_seller: 0,
    isBatteryHealthy: null,
    haveCharger: null,
    url_contract: '',
    created_at: '',
    updated_at: ''
  },
  formAnswers: [],
  statusHistory: [],
  questions: [],
  answers: [],
  entrys: [],
  certificateTheft: '',
  certificatePossession: '',
  actionType: '',
  certificateType: '',
  openModal: false,
  batteryLife: '',
  emailRecipient: '',
  rut: '',
  address: '',
  state: '',
  city: '',
  civil_state: '',
  serie: '',
  files: [],
  reload: false,
  loading: false,
  displayContract: 0,
  phone: ''
}

export const TradeinContext = createContext<TradeinContextType | null>(null)

export const TradeinProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ctx, setCtx] = useState<ITradeinContext>(EmptyObject)
  const userState = useSelector((store: IAppStore) => store.user)
  const { id } = useParams()
  const saveCtx = (_ctx: ITradeinContext) => {
    setCtx(_ctx)
  }

  const loadData = (): void => {
    setCtx({ ...ctx, loading: true })
    Promise.all([
      webApiService.getById({ id_tradein: String(id), email: userState.email, id_role: userState.id_role }),
      webApiService.getChecklistQuestions(),
      webApiService.getEntrysById(String(id)),
      webApiService.getTradeinConfig().then((res) => res.data[0]),
      webApiService.getAllAnswers(String(id)).then((res) => res.data)
    ]).then((res) => {
      setCtx({ ...ctx, tradein: res[0].data[0], loading: false, questions: res[1].data, entrys: res[2].data, reload: false, displayContract: res[3].display_contract, formAnswers: res[4] })
    }).catch((error) => {
      setCtx({ ...ctx, reload: false })
      console.log('Error loading data:', error)
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.reload])

  return <TradeinContext.Provider value={{ ctx, saveCtx }}>{children}</TradeinContext.Provider>
}
