import { webApiService } from '@/services'
import { Radio, RadioGroup, Skeleton } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { enqueueSnackbar } from 'notistack'
import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react'
import { useFormularioClienteContext } from '../hooks/useFormularioClienteContext'
import { IConfigSeller } from '@/models/configSeller'
import { useSelector } from 'react-redux'

const StepTwo: React.FC = () => {
  const { ctx, saveCtx } = useFormularioClienteContext()
  const [data, setData] = useState<{ loading: boolean, questionList: any[] }>({ loading: false, questionList: [] })
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  const loadData = async () => {
    setData({ ...data, loading: true })
    webApiService.getAllQuestions().then(res => {
      if (res.success) {
        setData({ questionList: res.data, loading: false })
        saveCtx({ ...ctx, steps: { ...ctx.steps, isValid: (ctx.data.answers.length === res.data.filter((q : any) => q.id_parent_question === null).length) } })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  const handleChangeQuestion = (event: ChangeEvent<HTMLInputElement>, idPregunta: number) => {
    // console.log('🚀 ~ handleChangeQuestion ~ arrResp:', data.questionList, (event.target as HTMLInputElement).value)

    const arr = [...ctx.data.answers]
    // console.log('🚀 ~ handleChangeQuestion ~ arr:', arr)

    if (arr.some(e => e.id_question === idPregunta)) {
      const idx = arr.findIndex(e => e.id_question === idPregunta)
      // console.log('🚀 ~ handleChangeQuestion ~ idx:', idx)
      arr.splice(idx, 1)
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    } else {
      arr.push({ id_question: idPregunta, id_answer: Number((event.target as HTMLInputElement).value), weigth: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).weigth, answer: data.questionList.find(q => q.id === Number((event.target as HTMLInputElement).value)).questions })
    }
    // console.log('Entro', ctx.data.answers.length, data.questionList.filter(q => q.id_parent_question === null).length)
    saveCtx({ ...ctx, data: { ...ctx.data, answers: arr }, steps: { ...ctx.steps, isValid: arr.length === data.questionList.filter(q => q.id_parent_question === null).length } })
  }

  useEffect(() => {
    loadData()
    return () => setData({ loading: false, questionList: [] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className='py-6'
    >
      {
        data.loading
          ? [1, 2, 3].map((p:any, i: number) => {
              return (
                <div key={i} className='border p-4 rounded-md mb-4'>
                  <h4 className='text-lg mb-3'>{p.questions}</h4>
                  <Skeleton className='h-5 w-3/5 rounded-lg' />
                  <div className='grid grid-cols-4 mt-6'>
                    {
                  [1, 2, 3, 4].map((_, k) => {
                    return (
                      <Skeleton key={k} className='h-5 w-3/5 rounded-lg' />
                    )
                  })
                }

                  </div>
                </div>
              )
            })
          : null
      }
      {
        data.questionList.filter(q => q.id_parent_question === null).map((p:any, i: number) => {
          return (
            <div key={i} className='border p-4 rounded-md mb-4'>
              <h4 className='text-lg mb-3'>{p.questions}</h4>
              <RadioGroup
                orientation='horizontal'
                onChange={(e) => handleChangeQuestion(e, p.id)}
                value={String(ctx.data.answers.find(a => a.id_question === p.id)?.id_answer)}
              >
                {
                  data.questionList.filter(a => Number(a.id_parent_question) === p.id).map((a:any, k: number) => {
                    return (
                      <Radio key={k} value={String(a.id)} style={{ '--seller-color': `${configSeller.config.color}` } as CSSProperties} classNames={{ control: 'bg-black', wrapper: 'group-data-[selected=true]:border-black' }}>{a.questions}</Radio>
                    )
                  })
                }
              </RadioGroup>
            </div>
          )
        })
      }
    </motion.div>
  )
}

export default StepTwo
