import { webApiService } from '@/services'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import StatusCard from './StatusCard'
import { MdAssignmentTurnedIn, MdCancel, MdCheckCircle, MdHourglassBottom, MdRequestQuote, MdThumbDown, MdTour, MdVerifiedUser } from 'react-icons/md'
import { IConfigSeller } from '@/models/configSeller'
import { useParams } from 'react-router-dom'

const ListCard = () => {
  const [data, setData] = useState<{ loading: boolean, listIndicadores: any[] }>({ loading: false, listIndicadores: [] })
  const userState = useSelector((store: any) => store.user)
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const isSamsungPeru = configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'
  const { slug } = useParams<{ slug: string }>()

  console.log('data => ', data)

  const loadData = (): void => {
    setData({ ...data, loading: true })
    webApiService.getCountStatus(userState.email, String(slug)).then((res) => {
      setData({
        listIndicadores: res.data,
        loading: false
      })
    })
      .catch((error) => {
        console.log('Error loading data:', error)
        setData({ ...data, loading: false })
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='grid gap-4 grid-cols-2 md:grid-cols-4'>
      <StatusCard loading={data.loading} color='#f5e7fa' indicador={data.listIndicadores.find(item => item.id_status === 1) !== undefined ? data.listIndicadores.find(item => item.id_status === 1).cantidad : 0} estado='Cotizado' textColor='#cf69f6' Icon={<MdRequestQuote style={{ fontSize: 60, color: '#cf69f6', opacity: 0.2 }} />} />

      <StatusCard loading={data.loading} color='#ffebee' indicador={data.listIndicadores.find(item => item.id_status === 2) !== undefined ? data.listIndicadores.find(item => item.id_status === 2).cantidad : 0} estado='Aprobado' textColor='#fe7070' Icon={<MdCheckCircle style={{ fontSize: 60, color: '#fe7070', opacity: 0.2 }} />} />

      <StatusCard loading={data.loading} color='#ffebee' indicador={data.listIndicadores.find(item => item.id_status === 6) !== undefined ? data.listIndicadores.find(item => item.id_status === 6).cantidad : 0} estado='No Aprobado' textColor='#fe7070' Icon={<MdCancel style={{ fontSize: 60, color: '#fe7070', opacity: 0.2 }} />} />

      <StatusCard loading={data.loading} color='#fff3e0' indicador={data.listIndicadores.find(item => item.id_status === 4) !== undefined ? data.listIndicadores.find(item => item.id_status === 4).cantidad : 0} estado='Validado' textColor='#fc9432' Icon={<MdVerifiedUser style={{ fontSize: 60, color: '#fc9432', opacity: 0.2 }} />} />

      <StatusCard loading={data.loading} color='#f1fdef' indicador={data.listIndicadores.find(item => item.id_status === 3) !== undefined ? data.listIndicadores.find(item => item.id_status === 3).cantidad : 0} estado='Firmado' textColor='#54c45e' Icon={<MdAssignmentTurnedIn style={{ fontSize: 60, color: '#54c45e', opacity: 0.2 }} />} />

      <StatusCard loading={data.loading} color='#e9f3ff' indicador={data.listIndicadores.find(item => item.id_status === 5) !== undefined ? data.listIndicadores.find(item => item.id_status === 5).cantidad : 0} estado='Entregado' textColor='#6db1ff' Icon={<MdHourglassBottom style={{ fontSize: 60, color: '#6db1ff', opacity: 0.2 }} />} />

      {isSamsungPeru
        ? <StatusCard loading={data.loading} color='#ebe7dd' indicador={data.listIndicadores.find(item => item.id_status === 11) !== undefined ? data.listIndicadores.find(item => item.id_status === 11).cantidad : 0} estado='En Bodega' textColor='#82755b' Icon={<MdTour style={{ fontSize: 60, color: '#82755b', opacity: 0.2 }} />} />
        : <StatusCard loading={data.loading} color='#ebe7dd' indicador={data.listIndicadores.find(item => item.id_status === 7) !== undefined ? data.listIndicadores.find(item => item.id_status === 7).cantidad : 0} estado='Retirado Reuse' textColor='#82755b' Icon={<MdTour style={{ fontSize: 60, color: '#82755b', opacity: 0.2 }} />} />}

      <StatusCard loading={data.loading} color='#e1fff9' indicador={data.listIndicadores.find(item => item.id_status === 8) !== undefined ? data.listIndicadores.find(item => item.id_status === 8).cantidad : 0} estado='Rechazado' textColor='#00c2a8' Icon={<MdThumbDown style={{ fontSize: 60, color: '#00c2a8', opacity: 0.2 }} />} />
    </div>
  )
}

export default ListCard
