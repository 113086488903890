import { brandLogo } from '@/utils/brandLogo'
import { currencyFormat } from '@/utils/currencyFormat'
import { StatusColor } from '@/utils/StatusColor.utility'
import moment from 'moment'
import { useTradeinContext } from '../hooks/useTradeinContext'

const Detail = () => {
  const { ctx } = useTradeinContext()

  return (
    <div className='bg-white rounded-lg shadow-lg p-4'>
      <h6 className='border-b-1 mb-2 font-bold text-xl pb-2'>Detalle</h6>
      <ul className='px-4'>
        <li className='py-4'>
          {
              ctx.tradein.wasRecalculated === 1
                ? (<img src={brandLogo(String(ctx.tradein.make))} alt={ctx.tradein.make} style={{ maxHeight: '40px', maxWidth: '100%' }} />)
                : (<img src={brandLogo(String(ctx.tradein.brand))} alt={ctx.tradein.brand} style={{ maxHeight: '40px', maxWidth: '100%' }} />)
            }
        </li>
        <li className='py-4'>
          <p>Estado actual</p>
          <div className='rounded-full inline text-sm text-white px-2 py-1' style={{ backgroundColor: StatusColor(ctx.tradein.status) }}>{ctx.tradein.status}</div>
        </li>
        <li className='py-4'>
          <p>Código de evaluación</p>
          <p className='text-sm text-gray-600'>{ctx.tradein.login_code ?? 'Sin código'}</p>
        </li>
        <li className='py-4'>
          <p>Monto de descuento</p>
          <p className='text-sm text-gray-600'><span className={ctx.tradein.new_discount_amount !== null ? 'line-through me-2' : 'me-2'}> {currencyFormat(ctx.tradein.discount_amount, import.meta.env.VITE_COUNTRY)}</span><span hidden={ctx.tradein.new_discount_amount === null}>{currencyFormat(ctx.tradein.new_discount_amount, import.meta.env.VITE_COUNTRY)}</span></p>
        </li>
        <li className='py-4'>
          <p>Fecha de creación</p>
          <p className='text-sm text-gray-600'>{moment(ctx.tradein.created_at).format('DD-MM-YYYY HH:mm')}</p>
        </li>
      </ul>
    </div>
  )
}

export default Detail
