import { webApiService } from '@/services'
import { Button } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { MdOutlineArrowOutward } from 'react-icons/md'
import { useParams } from 'react-router-dom'

const ForwardGiftcard = () => {
  const [data, setData] = useState<{ loading: boolean }>({ loading: false })
  const { id, slug } = useParams()

  const handleForward = () => {
    setData({ ...data, loading: true })
    webApiService.sendGiftcard({ seller: String(slug), id }).then(() => {
      setData({ ...data, loading: false })
      enqueueSnackbar('Giftcard enviada correctamente', { variant: 'success' })
    }).catch((error) => {
      enqueueSnackbar('Hubo un error al enviar giftcard', { variant: 'error' })
      setData({ ...data, loading: false })
      console.log('error ==>', error)
    })
  }

  return (
    <Button
      variant='shadow'
      color='success'
      size='sm'
      className='ml-2'
      endContent={<MdOutlineArrowOutward style={{ fontSize: '12px' }} />}
      onClick={() => { handleForward() }}
    >
      Reenviar Giftcard
    </Button>
  )
}

export default ForwardGiftcard
