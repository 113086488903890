export const getMonto: (data: any, slug: string) => number = (data, slug) => {
  const weigth = 100
  let resultado = 0

  const sumaPeso = data.answers.reduce((valorAnterior: any, valorActual: any) => { return valorAnterior + valorActual.weigth }, 0)
  if (sumaPeso >= 100) {
    return 0
  }

  if (slug !== 'macstore') {
    resultado = data.capacity.id_capacity > 0 ? data.capacity.amount - ((data.capacity.amount * sumaPeso) / 100) : data.model.amount - ((data.model.amount * sumaPeso) / 100)
  } else {
    const result = weigth - sumaPeso
    if (result === 100) {
      resultado = data.capacityModel.find((ele: any) => ele.id_capacity === data.capacity.id_capacity && ele.grade === 'A').amount
    } else if (result > 90) {
      resultado = data.capacityModel.find((ele: any) => ele.id_capacity === data.capacity.id_capacity && ele.grade === 'B').amount
    } else if (result > 70) {
      resultado = data.capacityModel.find((ele: any) => ele.id_capacity === data.capacity.id_capacity && ele.grade === 'C').amount
    }
  }

  console.log('resultado => ', resultado)

  return resultado
}
