import { IConfigSeller } from '@/models/configSeller'
import { webApiService } from '@/services'
import { htmlReplaceContract } from '@/utils/htmlReplaceContract'
import { Button, Link, Spinner } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { MdBorderColor } from 'react-icons/md'
import ModalSign from './components/ModalSign'

const ContractPage = () => {
  const [data, setData] = useState<{ loading: boolean, tradein: any, openModal: boolean, firma: string }>({ loading: false, tradein: null, openModal: false, firma: '' })
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const { loginCode, slug } = useParams<{ loginCode: string, slug: string }>()
  console.log('data.tradein ==>', data.tradein)
  const html = data.tradein !== null && data.tradein !== undefined
    ? htmlReplaceContract(configSeller.config.html, {
      id: data.tradein.id,
      name: String(data.tradein.name),
      lastname: String(data.tradein.lastname),
      rut: String(data.tradein.rut),
      city: data.tradein.city,
      address: String(data.tradein.address),
      state: String(data.tradein.state),
      sbu: String(data.tradein.sbu),
      brand: data.tradein.wasRecalculated === 1 ? String(data.tradein.make) : String(data.tradein.brand),
      model: data.tradein.wasRecalculated === 1 ? String(data.tradein.modelName) : String(data.tradein.model),
      imei: String(data.tradein.serie),
      discount_amount: data.tradein.new_discount_amount !== null ? String(data.tradein.new_discount_amount) : String(data.tradein.discount_amount),
      seller: slug,
      civil_state: String(data.tradein.civil_state),
      capacity: String(data.tradein.capacity),
      email: data.tradein.email,
      movil: ''
    })
    : ''
  let htmlVisual = html.replace('{{firma}}', data.firma)

  // Para barcodes Macstore
  if (slug === 'macstore') {
    htmlVisual = htmlVisual.replace('{{barCode1}}', '')
    htmlVisual = htmlVisual.replace('{{barCode2}}', '')
    htmlVisual = htmlVisual.replace('{{barCode3}}', '')
    htmlVisual = htmlVisual.replace('{{barCode4}}', '')
  }

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getByEnvelopeId(String(loginCode)).then(res => {
      setData({ ...data, loading: false, tradein: res.data.length === 0 ? undefined : res.data[0] })
      console.log('🚀 ~ webApiService.getByEnvelopeId ~ res.data[0]:', res.data)
    })
      .catch((error) => {
        setData({ ...data, loading: false })
        console.log('Error loading data:', error)
      })
  }

  const handleSignContract = () => {
    setData({ ...data, openModal: true })
  }

  const saveFirma = (firma: string) => {
    setData({ ...data, firma, openModal: false })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginCode])

  if (data.tradein === null || data.loading) {
    return (
      <div className='bg-slate-800 w-full min-h-screen'>
        <div className='container mx-auto py-11'>
          <div className='grid grid-cols-2 max-w-[1024px] pb-2 mx-auto'>
            <div className='flex items-center'><img src={configSeller.config.logo} className='h-14' /></div>
            <div className='flex items-center'><img src={`https://${import.meta.env.VITE_BUCKET}.s3.us-west-2.amazonaws.com/assets/logo-reuse.png`} className='h-16 ms-auto' /></div>
          </div>
          <div className='bg-white border-black border-1 p-11 max-w-[1024px] mx-auto'>
            <Spinner />
          </div>
        </div>
      </div>
    )
  }

  if (data.tradein.url_contract !== null && data.tradein.url_contract !== '') {
    return (
      <div className='bg-slate-800 w-full min-h-screen'>
        <div className='container mx-auto py-11'>
          <div className='grid grid-cols-2 max-w-[1024px] pb-2 mx-auto'>
            <div className='flex items-center'><img src={configSeller.config.logo} className='h-14' /></div>
            <div className='flex items-center'><img src={`https://${import.meta.env.VITE_BUCKET}.s3.us-west-2.amazonaws.com/assets/logo-reuse.png`} className='h-16 ms-auto' /></div>
          </div>
          <div className='bg-white border-black border-1 p-11 max-w-[1024px] mx-auto text-center'>
            Este contrato ya fue firmado, para descargarlo haga click en el botón
            <div className='py-4'>
              <Button size='lg' color='success' href={data.tradein.url_contract} target='_blank' as={Link}>Descargar</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='bg-slate-800 w-full min-h-screen'>
      <div className='container mx-auto p-1 md:py-11'>
        {
          (data.tradein.status === 'VALIDADO' || data.tradein.status === 'EN BODEGA') && data.firma === ''
            ? <Button className='fixed bottom-16 right-16' variant='shadow' color='success' size='sm' startContent={<MdBorderColor />} onClick={handleSignContract}>Firmar</Button>
            : null
        }
        <div className='grid grid-cols-2 max-w-[1024px] pb-2 mx-auto'>
          <div className='flex items-center'><img src={configSeller.config.logo} className='h-14' /></div>
          <div className='flex items-center'><img src={`https://${import.meta.env.VITE_BUCKET}.s3.us-west-2.amazonaws.com/assets/logo-reuse.png`} className='h-16 ms-auto' /></div>
        </div>
        <div className='bg-white border-black border-1 p-11 max-w-[1024px] mx-auto'>
          <div dangerouslySetInnerHTML={{ __html: htmlVisual }} />
        </div>
      </div>
      {data.openModal ? <ModalSign isOpen={data.openModal} saveFirma={saveFirma} tiData={{ id: data.tradein.id, discountAmount: data.tradein.new_discount_amount !== null ? data.tradein.new_discount_amount : data.tradein.discount_amount, imei: data.tradein.serie, id_model: data.tradein.id_model, grade: data.tradein.grade }} onClose={() => setData({ ...data, openModal: false })} html={html} /> : null}
    </div>
  )
}

export default ContractPage
