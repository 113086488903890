import { PrivateRoutes } from '@/constants/routes'
import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { MdArrowBackIos, MdDone } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTradeinContext } from '../hooks/useTradeinContext'
import CancelTradein from './CancelTradein'
import CheckFeasibility from './CheckFeasibility'
import Recalculate from './Recalculate'
import ResendCode from './ResendCode'
import { IConfigSeller } from '@/models/configSeller'
import InWarehouse from './InWarehouse'
import ForwardGiftcard from './ForwardGiftcard'

const Header = () => {
  const [data, setData] = useState<{ loading: boolean, open: boolean, rut: string }>({ loading: false, open: false, rut: '' })
  const navigate = useNavigate()
  const { ctx, saveCtx } = useTradeinContext()
  const { id, slug } = useParams()
  const userState = useSelector((store: IAppStore) => store.user)
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  const handleRut = () => {
    setData({ ...data, open: true })
  }

  const handleClose = () => {
    setData({ ...data, open: false })
  }
  const handleRecibir = () => {
    setData({ ...data, loading: true })
    webApiService.update({ id_tradein: String(id), id_user: userState.id, stage: 6, id_role: userState.id_role, email: userState.email, client_email: ctx.tradein.email, id_seller: ctx.tradein.id_seller, rut: data.rut, client_name: ctx.tradein.name, url_contract: ctx.tradein.url_contract })
      .then((data) => {
        enqueueSnackbar('Estado actualizado a Entregado', { variant: 'success' })
        setData({ ...data, loading: false, rut: '' })
        console.log('data', data)
        saveCtx({ ...ctx, reload: true })
      })
      .catch((error: any) => {
        if (error.status === 500 && error.response.data !== undefined) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' })
          enqueueSnackbar('Recargando pagina', { variant: 'warning' })
          saveCtx({ ...ctx, reload: true })
        } else {
          enqueueSnackbar(error.message, { variant: 'error' })
        }

        setData({ ...data, loading: false, rut: '' })
        console.log('error ==>', error)
      })
  }

  return (
    <div className='grid grid-cols-2'>
      <div className='flex items-center'>
        <Button
          variant='ghost' size='sm' color='primary' onClick={() => navigate(PrivateRoutes.HOME.replace(':slug', String(slug)))} startContent={
            <MdArrowBackIos className='text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0' />
        }
        >Volver
        </Button>
        <h1 className='font-black md:text-2xl ml-2'>{ctx.tradein.brand + ' - ' + ctx.tradein.model}</h1>
      </div>
      <div className='flex justify-end'>
        {(ctx.tradein.sbu === 'Smartphone' && ctx.tradein.id_status === 6 && ![Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role))
          ? (
            <ResendCode />
            )
          : null}
        {((ctx.tradein.id_status === 3 || (ctx.displayContract === 0 && ctx.tradein.id_status === 4)) && ctx.tradein.hasFiles === 1 && ![Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role))
          ? (
            <>
              <Button
                color='primary'
                variant='shadow'
                size='sm'
                style={{ marginLeft: '5px' }}
                endContent={<MdDone style={{ fontSize: '15px' }} />}
                onClick={String(configSeller.config.s3.FOLDER_APP) === 'ti_falabella' ? handleRut : handleRecibir}
                isLoading={data.loading}
              >
                {String(configSeller.config.s3.FOLDER_APP) === 'ti_falabella' ? 'Recibir y emitir giftcard' : 'Recibir equipo'}
              </Button>
              <Modal backdrop='blur' isOpen={data.open} onClose={handleClose}>
                <ModalContent>
                  <ModalHeader className='flex flex-col gap-1'>Emitir Gift Card</ModalHeader>
                  <ModalBody>
                    <p className='font-bold'>¿El cliente te ha entregado el dispositivo restablecido y desvinculado?</p>
                    <p>Si aprietas "Ok" se emitirá la Gift Card</p>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color='danger'
                      onClick={handleClose}
                    >
                      Cerrar
                    </Button>
                    <Button color='primary' type='submit' onClick={handleRecibir}>
                      {data.loading ? 'Enviando...' : 'Ok'}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
            )
          : null}

        {(ctx.tradein.recalculate === '1' && ctx.tradein.wasRecalculated === null && ctx.tradein.id_status !== 8 && ![Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role))
          ? (
            <Recalculate />
            )
          : null}
        {
            ctx.tradein.certificate_theft === 'VALID' && ctx.tradein.id_status === 8 && ctx.tradein.sbu === 'Smartphone' && ![Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role)
              ? (
                <CheckFeasibility />
                )
              : null
          }
        {ctx.tradein.id_status === 4 && ![Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role) && configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'
          ? (
            <InWarehouse />
            )
          : null}
        {
            configSeller.config.s3.FOLDER_APP === 'ti_falabella' && [5, 7, 9].includes(ctx.tradein.id_status)
              ? (
                <ForwardGiftcard />
                )
              : null
          }
        {ctx.tradein.id_status !== 5 && ctx.tradein.id_status !== 8 && ![Number(import.meta.env.VITE_PERFIL_SOLOLECTURA)].includes(userState.id_role)
          ? (
            <CancelTradein />
            )
          : null}

      </div>
    </div>
  )
}

export default Header
