import { currencyFormat } from '@/utils/currencyFormat'
import { getMonto } from '@/utils/getMonto'
import { Input, Select, SelectItem } from '@nextui-org/react'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useFormularioClienteContext } from '../hooks/useFormularioClienteContext'
import { webApiService } from '@/services'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { IConfigSeller } from '@/models/configSeller'
import { useSelector } from 'react-redux'

const StepThree: React.FC = () => {
  const { ctx, saveCtx } = useFormularioClienteContext()
  const [data, setData] = useState<{ storeList: any[], loading: boolean }>({ storeList: [], loading: false })
  const { slug } = useParams()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  const loadData = async () => {
    setData({ ...data, loading: true })
    webApiService.getAllStoreBySeller(String(slug)).then(res => {
      if (res.success) {
        setData({ storeList: res.data, loading: false })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    if (configSeller.config.s3.FOLDER_APP === 'ti_coppel') loadData()
    return () => setData({ loading: false, storeList: [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.3 }}
      className='py-6'
    >
      <h3 className='mb-2 mt-4 text-center font-bold'>Verifica tu equipo</h3>
      <div className='container mb-3'>
        <div className=' border-black border rounded grid grid-cols-2'>
          <div className='p-4 border-r'>
            <h5 className='font-bold text-xl'>{ctx.data.sbu.name}</h5>
            {
                ctx.data.sbu.id_sbu !== 2
                  ? (<p className='mb-0'>{ctx.data.model.model} - {ctx.data.capacity.capacity}</p>)
                  // : (ctx.data.sbu.id_sbu === 2 && ctx.data.brand.id_brand === 2)
                  //     ? (<p className='mb-0'>{ctx.data.model.model}</p>)
                  : (ctx.data.sbu.id_sbu === 2 && ctx.data.brand.id_brand === 1 && ctx.data.model.capacity === 0)
                      ? (<p className='mb-0'>{ctx.data.model.model}</p>)
                      : (ctx.data.sbu.id_sbu === 2 && ctx.data.brand.id_brand === 1 && ctx.data.model.capacity !== 0)
                          ? (<p className='mb-0'>{ctx.data.model.model} {ctx.data.cpu.name ? `- ${ctx.data.cpu.name}` : null}  - {ctx.data.screen.screen} - {ctx.data.ram.ram} ram - {ctx.data.capacity.capacity}</p>)
                          : (<p className='mb-0'>{ctx.data.cpu.name} - {ctx.data.screen.screen} - {ctx.data.ram.ram} ram - {ctx.data.capacity.capacity}</p>)
              }
          </div>
          <div className='p-4'>
            <p className='font-bold mb-1 text-end'>Monto de descuento</p>
            <p className='h5 font-black text-end'>{currencyFormat(getMonto(ctx.data, String(slug)), import.meta.env.VITE_COUNTRY)}</p>

          </div>
        </div>
      </div>
      <div className='max-w-[600px] mx-auto'>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <Input
              type='text'
              variant='bordered'
              label='Nombre'
              value={ctx.data.name}
              fullWidth
              errorMessage={ctx.validation && ctx.data.name.trim() === '' ? 'Debe ingresar su nombre' : ''}
              isInvalid={ctx.validation && ctx.data.name.trim() === ''}
              autoComplete='off'
              onChange={(e) => saveCtx({ ...ctx, data: { ...ctx.data, name: e.target.value } })}
            />
          </div>
          <div>
            <Input
              type='text'
              variant='bordered'
              label='Apellido'
              value={ctx.data.lastname}
              fullWidth
              errorMessage={ctx.validation && ctx.data.lastname.trim() === '' ? 'Debe ingresar su apellido' : ''}
              isInvalid={ctx.validation && ctx.data.lastname.trim() === ''}
              autoComplete='off'
              onChange={(e) => saveCtx({ ...ctx, data: { ...ctx.data, lastname: e.target.value } })}
            />
          </div>

          <div className='col-span-2'>
            <Input
              type='email'
              variant='bordered'
              label='Correo electronico'
              value={ctx.data.email}
              fullWidth
              errorMessage={ctx.validation && ctx.data.email.trim() !== '' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ctx.data.email) ? 'Debe ingresar un correo válido' : ''}
              isInvalid={ctx.validation && ctx.data.email.trim() !== '' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ctx.data.email)}
              autoComplete='off'
              onBlur={() => saveCtx({ ...ctx, validation: ctx.data.email.trim() !== '' })}
              onChange={(e) => saveCtx({ ...ctx, data: { ...ctx.data, email: String(e.target.value) } })}
            />
          </div>
          {
            configSeller.config.s3.FOLDER_APP === 'ti_coppel'
              ? (
                <div className='col-span-2'>
                  <Select
                    variant='bordered'
                    size='lg'
                    aria-label='Select'
                    scrollShadowProps={{
                      isEnabled: true
                    }}
                    className='w-full'
                    placeholder='Seleccione una tienda'
                    isLoading={data.loading}
                    isDisabled={data.loading}
                    selectedKeys={ctx.data.id_store === 0 ? undefined : [JSON.stringify(ctx.data.id_store)]}
                    onChange={(value : any) => { saveCtx({ ...ctx, data: { ...ctx.data, id_store: Number(value.target.value) } }) }}
                  >
                    {data.storeList.map((s) => (
                      <SelectItem key={s.id}>
                        {s.store}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
                )
              : null
          }
        </div>
      </div>
    </motion.div>
  )
}

export default StepThree
