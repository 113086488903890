import { IAppStore } from '@/models/store.model'
import { webApiService } from '@/services'
import { blobToBase64 } from '@/utils/blobToBase64.util'
import { pdf } from '@react-pdf/renderer'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import ManifiestoPDF from '../../ManifiestoPDF'
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { IConfigSeller } from '@/models/configSeller'
import NUIAlert from '@/components/Alert/Alert'

const ModalCustodia: React.FC<{ list: any[], seleccionados: any[], reloadAction: () => void, visible: boolean, tab: string }> = ({ list, seleccionados, reloadAction, visible, tab }) => {
  const [data, setData] = useState<{ loading: boolean, open: boolean, form: { name: string, lastName: string, first_seal: string, second_seal: string, signature: string, seal_weight: string } }>({ loading: false, open: false, form: { name: '', lastName: '', first_seal: '', second_seal: '', signature: '', seal_weight: '' } })
  const signatureCanvasRef = useRef<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const userState = useSelector((store: IAppStore) => store.user)
  const { slug } = useParams<{ slug: string }>()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)

  const handleClose = () => {
    setData({ ...data, open: false, loading: false })
  }

  const handleCustody = async () => {
    if (signatureCanvasRef.current.isEmpty()) {
      enqueueSnackbar('El receptor debe firmar', { variant: 'error' })
      return
    }

    setData({ ...data, loading: true })
    console.log('form ===>', data.form)

    try {
      if (tab === 'custodia') {
        const res = await webApiService.checkSeal({ seal: data.form.first_seal, seal2: data.form.second_seal })
        if (res.data.length > 0) {
          enqueueSnackbar('Uno de los sellos ya existe, utilice otro por favor.', { variant: 'error' })
          setData({ ...data, loading: false })
          return
        }
      }

      console.log('listado => ', list.filter((e) => seleccionados.includes(e.id)), list, seleccionados)

      const pdfBlob = await pdf(<ManifiestoPDF firma={signatureCanvasRef.current.toDataURL('image/png')} detalle={data.form} listado={list.filter((e) => seleccionados.map(e => Number(e)).includes(e.id))} />).toBlob()
      console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
      const base64PDF = await blobToBase64(pdfBlob)
      console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)

      await webApiService.sendToCustody({ ...data.form, seller: slug, signature: signatureCanvasRef.current.toDataURL('image/png'), modificados: list.filter((e) => seleccionados.map(e => Number(e)).includes(e.id)), id_user: userState.id, pdf: base64PDF, tab })
      enqueueSnackbar('Folios guardados correctamente.', { variant: 'success' })
      setData({ ...data, open: false, loading: false, form: { name: '', lastName: '', first_seal: '', second_seal: '', signature: '', seal_weight: '' } })
      reloadAction()
    } catch (err: any) {
      setData({ ...data, loading: false })
      enqueueSnackbar('Error: ' + err.message, { variant: 'error' })
    }
  }

  const clearSignature = async () => {
    if (signatureCanvasRef.current !== null) {
      signatureCanvasRef.current.clear()
    }
  }

  console.log('seleccionados', seleccionados)
  return (
    <div>
      {visible
        ? <Button color='primary' className='mx-1' disabled={seleccionados.length === 0 || !visible} onClick={() => { setData({ ...data, open: true, form: { ...data.form, name: userState.name.split(' ')[0], lastName: userState.name.split(' ')[1] } }) }}>{tab === 'custodia' ? 'Asignar sellos' : 'Recibir en custodia'}</Button>
        : <div className='bg-blue-200'>Para entregar a custodia, ingrese folio a todos los registros</div>}
      <Modal
        isOpen={data.open}
        onClose={handleClose}
        placement='top-center'
        backdrop='blur'
        size='xl'
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>Recibir en custodia</ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  required
                  fullWidth
                  value={data.form.name}
                  onChange={e => { setData({ ...data, form: { ...data.form, name: e.target.value } }) }}
                  id='name'
                  label='Nombre'
                  name='name'
                />
                <Input
                  required
                  fullWidth
                  value={data.form.lastName}
                  onChange={e => { setData({ ...data, form: { ...data.form, lastName: e.target.value } }) }}
                  id='lastName'
                  label='Apellido'
                  name='lastName'
                />
                {tab === 'custodia'
                  ? (
                    <>
                      <Input
                        required
                        fullWidth
                        value={data.form.first_seal}
                        onChange={e => { setData({ ...data, form: { ...data.form, first_seal: e.target.value } }) }}
                        id='first_seal'
                        label='1° Sello'
                        name='seal'
                      />
                      <Input
                        required
                        fullWidth
                        value={data.form.second_seal}
                        onChange={e => { setData({ ...data, form: { ...data.form, second_seal: e.target.value } }) }}
                        id='second_seal'
                        label='2° Sello'
                        name='seal'
                        errorMessage
                      />
                      <Input
                        required
                        fullWidth
                        value={data.form.seal_weight}
                        onChange={e => { setData({ ...data, form: { ...data.form, seal_weight: e.target.value } }) }}
                        id='seal_weight'
                        label='Peso'
                        name='seal_weight'
                      />

                      {data.form.first_seal === data.form.second_seal && data.form.first_seal !== '' ? <div className='my-4'><NUIAlert type='error'>Los sellos no pueden ser iguales.</NUIAlert></div> : null}
                    </>
                    )
                  : null}

                <div>
                  <p>Firmar:</p>
                  <div style={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', display: 'inline-block' }}>
                    <SignatureCanvas penColor='black' canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} />
                  </div>
                  <div>
                    <Button variant='flat' onPress={clearSignature}>
                      Limpiar firma
                    </Button>
                  </div>
                </div>

              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='flat' onPress={onClose}>
                  Cancelar
                </Button>
                <Button
                  color='primary' onPress={handleCustody}
                  isLoading={data.loading}
                  isDisabled={
                  data.form.name === '' ||
                  data.form.lastName === '' ||
                  (data.form.first_seal === data.form.second_seal && tab === 'custodia') ||
                  (data.form.first_seal === '' && tab === 'custodia') ||
                  (data.form.second_seal === '' && tab === 'custodia') ||
                  (data.form.seal_weight === '' && configSeller.config.permisos.WEIGHT_REQUIRED === 1)
                  }
                >
                  Recibir
                </Button>
              </ModalFooter>
            </>
          )}

        </ModalContent>
      </Modal>
    </div>
  )
}
export default ModalCustodia
