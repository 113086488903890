import { IConfigSeller } from '@/models/configSeller'
import { webApiService } from '@/services'
import { blobToBase64 } from '@/utils/blobToBase64.util'
import { generateBarcodeBase64 } from '@/utils/makeBarCode.util'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react'
import { Document, Page, pdf } from '@react-pdf/renderer'
import { fromImage } from 'imtool'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import Html from 'react-pdf-html'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
interface IOwnProps {
    isOpen: boolean
    onClose: () => void
    html: string
    saveFirma: (firma: string) => void
    tiData: any
}

const ModalSign: FC<IOwnProps> = ({ onClose, isOpen, html, saveFirma, tiData }) => {
  const [data, setData] = useState<{ loading: boolean, files: any[] }>({ loading: false, files: [] })
  const signatureCanvasRef = useRef<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { loginCode, slug } = useParams<{ loginCode: string, slug: string }>()
  const configSeller = useSelector((store: { config: IConfigSeller }) => store.config)
  const isSamsungPeru = configSeller.config.s3.FOLDER_APP === 'ti_samsung_pe'

  console.log('tiDatatiDatatiData ==>', tiData)
  const clearSignature = async () => {
    if (signatureCanvasRef.current !== null) {
      signatureCanvasRef.current.clear()
    }
  }

  const signContract = async () => {
    try {
      if (signatureCanvasRef.current.isEmpty()) {
        enqueueSnackbar('Ingrese su firma', { variant: 'warning' })
        return
      }
      setData({ ...data, loading: true })
      html = html.replace('{{firma}}', '<img src="' + signatureCanvasRef.current.toDataURL('image/png') + '">')
      let htmlPdf1 = html
      let htmlPdf2 = html

      if (slug === 'macstore') {
        htmlPdf1 = htmlPdf1.replace('{{barCode1}}', '')
        htmlPdf1 = htmlPdf1.replace('{{barCode2}}', '')
        htmlPdf1 = htmlPdf1.replace('{{barCode3}}', '')
        htmlPdf1 = htmlPdf1.replace('{{barCode4}}', '')
      }

      // convertir el html a pdf
      const pdfBlob = await pdf(<Document> <Page size='A4' style={{ fontFamily: 'Times-Roman', fontWeight: 'bold' }}> <Html style={{ margin: '40px' }} resetStyles={false}>{htmlPdf1}</Html> </Page></Document>).toBlob()
      console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
      const base64PDF = await blobToBase64(pdfBlob)
      console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)
      let base64PDFMacstore: any = ''

      if (slug === 'macstore') {
        const sku = await webApiService.getSkuByModel({ grade: tiData.grade, id_model: tiData.id_model })
          .then(res => {
            console.log('🚀 ~ Resultado de getSkuByModel:', res)
            return res
          })
          .catch(error => {
            console.log('Error al obtener el SKU:', error)
            return null
          })

        console.log('🚀 ~ sku:', sku)

        const barCodeText1 = '(1)OC' + String(moment().tz('America/Mexico_City').locale('es').format('DDMMYYYY') + String(tiData.discountAmount).padEnd(5, '0') + '996')
        const barCodeText2 = '(2)REUSE-' + String(tiData.id)
        const barCodeText3 = '(3)' + String(tiData.imei)
        const barCodeText4 = '(4)' + String(sku.data)
        console.log(barCodeText1)
        htmlPdf2 = htmlPdf2.replace('{{barCode1}}', '<img style="height:80px" src="' + String(generateBarcodeBase64(barCodeText1)) + '">')
        htmlPdf2 = htmlPdf2.replace('{{barCode2}}', '<img style="height:80px" src="' + String(generateBarcodeBase64(barCodeText2)) + '">')
        htmlPdf2 = htmlPdf2.replace('{{barCode3}}', '<img style="height:80px" src="' + String(generateBarcodeBase64(barCodeText3)) + '">')
        htmlPdf2 = htmlPdf2.replace('{{barCode4}}', '<img style="height:80px" src="' + String(generateBarcodeBase64(barCodeText4)) + '">')

        // convertir el html a pdf
        const pdfBlobMacstore = await pdf(<Document> <Page size='A4' style={{ fontFamily: 'Times-Roman', fontWeight: 'bold' }}> <Html style={{ margin: '40px' }} resetStyles={false}>{htmlPdf2}</Html> </Page></Document>).toBlob()
        console.log('🚀 ~ pdfBlobMacstore:', pdfBlobMacstore)
        base64PDFMacstore = await blobToBase64(pdfBlobMacstore)
        console.log('🚀 ~ base64PDFMacstore:', base64PDFMacstore)
      }

      if (isSamsungPeru) {
        for (const element of data.files) {
          if (element.nameFile === undefined) {
            console.log('entro', data)
            delete element.preview
            element.blob = await fromImage(element).then((tool: any) => tool.thumbnail(800, false).toBlob())
            element.base64 = await blobToBase64(element.blob)
            delete element.blob
          }
        }
        console.log('🚀 ~ forawait ~ ctx.files:', data.files)
      }

      await webApiService.signContract({ signature: signatureCanvasRef.current.toDataURL('image/png'), pdf: base64PDF, pdf_2: base64PDFMacstore, seller: slug, envelopeId: loginCode, files: data.files }).then(res => {
        console.log('🚀 ~ awaitwebApiService.signContract ~ res:', res)
        saveFirma('<img src="' + signatureCanvasRef.current.toDataURL('image/png') + '">')
        enqueueSnackbar('Contrato firmado.', { variant: 'success' })

        // descargar pdf
        const link = document.createElement('a')
        link.target = '_blank'
        link.href = res.data
        link.download = `${loginCode}.pdf`
        link.click()
        setData({ ...data, loading: false })
      }).catch(error => {
        console.log('🚀 ~ awaitwebApiService.signContract ~ error:', error)
        enqueueSnackbar('Error al firmar', { variant: 'error' })
        setData({ ...data, loading: false })
      })
    } catch (err: any) {
      enqueueSnackbar('Error: ' + err.message, { variant: 'error' })
    }
  }

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    fileRejections.map(({ file, errors }) => {
      return enqueueSnackbar(`${errors[0].message}. Your file ${file.size}`, { variant: 'error' })
    })

    const validFiles = acceptedFiles.filter(file =>
      ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    console.log('Asegúrate', [...data.files, ...validFiles])
    setData({
      ...data,
      files: [...data.files, ...validFiles]
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg']
    },
    maxFiles: 2,
    maxSize: 1000000
  })

  const removeFile = (file: File, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setData({
      ...data,
      files: data.files.filter(f => f !== file)
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      placement='top-center'
      backdrop='blur'
      size='xl'
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='flex flex-col gap-1'>Firmar contrato</ModalHeader>
            <ModalBody>
              <div>
                {
                  isSamsungPeru
                    ? (
                      <>
                        <p className='text-base mt-2 font-bold'>Se requiere 2 imagenes de su DNI (Frontal y Trasera)</p>
                        <div
                          {...getRootProps()} style={{
                            border: '2px dashed #cccccc',
                            borderRadius: '5px',
                            padding: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            backgroundColor: isDragActive ? '#cce5ff' : '#ffffff',
                            color: isDragActive ? '#ffffff' : '#000000'
                          }}
                        >
                          <input {...getInputProps()} />
                          {
                          isDragActive
                            ? (
                              <p>Arrastra y suelta las imágenes aquí...</p>
                              )
                            : (
                              <p>Arrastra y suelta las imágenes aquí, o haz clic para seleccionar archivos</p>
                              )
                        }
                          <p color='textSecondary'>
                            (Solo se permiten archivos .png, .jpg y .jpeg)
                          </p>
                          <div className='grid grid-cols-12'>
                            {data.files.map((file, index) => (
                              <div key={index} className='col-span-6'>
                                <img src={URL.createObjectURL(file)} alt={`preview ${index}`} style={{ width: '100%' }} />
                                <p>{file.name}</p>
                                <Button color='secondary' size='sm' onClick={(event: any) => removeFile(file, event)}>
                                  Eliminar
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                      )
                    : null

                }

                <p className='text-base mt-2 font-bold'>Firmar en el recuadro</p>
                <div style={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', display: 'inline-block' }}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} />
                </div>
                <div>
                  <Button variant='flat' onPress={clearSignature}>
                    Limpiar firma
                  </Button>
                </div>
              </div>

            </ModalBody>
            <ModalFooter>
              <Button color='danger' variant='flat' onPress={onClose}>
                Cancelar
              </Button>
              <Button
                color='primary'
                onPress={signContract}
                isLoading={data.loading}
                isDisabled={data.files.length < 2 && isSamsungPeru}
              >
                Firmar
              </Button>
            </ModalFooter>
          </>
        )}

      </ModalContent>
    </Modal>
  )
}

export default ModalSign
