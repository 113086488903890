import moment from 'moment-timezone'

export const UTCDateToCountryDate = (date: string) => {
  const fecha = moment.utc(date).format()
  let dateRet = ''
  if (String(import.meta.env.VITE_COUNTRY) === 'MX') {
    dateRet = moment(fecha).tz('America/Mexico_City').format('DD-MM-YYYY HH:mm:ss')
  }
  if (String(import.meta.env.VITE_COUNTRY) === 'PE') {
    dateRet = moment(fecha).tz('America/Lima').format('DD-MM-YYYY HH:mm:ss')
  }
  if (String(import.meta.env.VITE_COUNTRY) === 'CL') {
    dateRet = moment(fecha).tz('America/Santiago').format('DD-MM-YYYY HH:mm:ss')
  }
  return dateRet
}

export const getTimeDiff = () => {
  let dateRet = ''
  if (String(import.meta.env.VITE_COUNTRY) === 'MX') {
    dateRet = moment().tz('America/Mexico_City').format('Z')
  }
  if (String(import.meta.env.VITE_COUNTRY) === 'PE') {
    dateRet = moment().tz('America/Lima').format('Z')
  }
  if (String(import.meta.env.VITE_COUNTRY) === 'CL') {
    dateRet = moment().tz('America/Santiago').format('Z')
  }
  return dateRet
}
